<template>
	<Settings></Settings>
</template>

<script>
import Settings from "@/components/shared/settings/Index.vue";

export default {
	name: "MentorSettingsPage",

	components: {
		Settings,
	},
};
</script>
